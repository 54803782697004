import { Backdrop, Box, Breadcrumbs, Button, Card, CardContent, CircularProgress, Divider, Link, Slide, Snackbar, Tab, Typography, Tabs, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, Table, CardHeader, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import React, { useEffect, useState, useRef, forwardRef } from 'react'
import ToppMeny from '../komponenter/Toppmeny'
import { Text } from '../komponenter/Tekst'
import axios from 'axios';
import HomeIcon from '@mui/icons-material/Home';
import EditIcon from '@mui/icons-material/Edit';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import QrCodeIcon from '@mui/icons-material/QrCode';
import InfoIcon from '@mui/icons-material/Info';
import { useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BusinessIcon from '@mui/icons-material/Business';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { TransitionProps } from '@mui/material/transitions';
import Grid from '@mui/material/Grid2';
import moment from 'moment';

const APIURL = process.env.REACT_APP_APIURL;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}



interface Aktor {
    Navn: string;
    Besoksadresse: string;
    Besokspostnummer: string;
    Besokssted: string;
    Mobilnummer: string;
    Epost: string;
    OrgNummer: string;
}


function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function InnsendingDetaljer() {
    const { aktorid } = useParams();
    const [valueTab, setValueTab] = React.useState(0);
    const [openSnack, setOpenSnack] = useState(false);
    const [openSnackError, setOpenSnackError] = useState(false);
    const [snackMelding, setSnackMelding] = useState("");
    const [isLoading, setisLoading] = useState(true);
    const [AktivAktorId, setAktivAktorId] = useState(0);
    const [aktor, setAktor] = useState<Aktor | null>(null);
    const [aktorEier, setAktorEier] = useState<Aktor | null>(null);
    const token = sessionStorage.getItem("jvtoken") || "";
    const [instrumenterGruppert, setInstrumenterGruppert] = useState<Record<string, InstrumentGruppert>>({});

    interface Instrument {
        InstrumentId: number;
        Merking?: string;
        Fabrikat?: string;
        Modell?: string;
        InstrumentType: string;
        Serienummer?: string;
        Jvstrekkode?: string;
        OpprettelsesDato?: string;
        SisteMeldingDato?: string;
        TypeprovingssertifikatNr?: string;
        MaksKapasitet?: string;
        Plombenavn?: string;
        Plombetype?: string;
        Plombenummer?: string;
        Plombeplassering?: string;
        Status?: string;
        InstrumentTypeId: number;
        Instrumentdeltype?: string;
        Kategori?: string;
        Delinger?: string;
    }

    interface InstrumentGruppert {
        InstrumentType: string;
        Instruments: Instrument[];
    }


    const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
        setOpenSnack(false);
    }

    const handleCloseSnackError = (event: React.SyntheticEvent | Event, reason?: string) => {
        setOpenSnackError(false);
    }


    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
    };

    function getaktor() {
        axios.get(APIURL + "/innsending/lokasjon/" + aktorid, { withCredentials: true, headers: { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' } })
            .then((response: any) => {
                if (response.status === 200) {
                    setAktor(response.data);
                    setAktorEier(response.data.eier);
                    console.log(response.data);
                    setAktivAktorId(response?.data?.AktorId);

                    const instrumenterGruppert = response.data?.instrumenter?.reduce((acc: Record<number, InstrumentGruppert>, instrument: Instrument) => {
                        if (!acc[instrument.InstrumentTypeId]) {
                            acc[instrument.InstrumentTypeId] = {
                                InstrumentType: instrument.InstrumentType,
                                Instruments: []
                            };
                        }

                        acc[instrument.InstrumentTypeId].Instruments.push(instrument);

                        return acc;
                    }, {} as Record<number, InstrumentGruppert>);

                    setInstrumenterGruppert(instrumenterGruppert);

                }
                else {
                }
                setisLoading(false);
            })
            .catch((e: any) => {
                console.log(e);
                setSnackMelding(e?.response?.data?.message);
                setOpenSnackError(true);
                setisLoading(false);
            });
    }

    useEffect(() => {
        getaktor();
        setisLoading(false);
        // eslint-disable-next-line
    }, []);


    return (
        <main>
            {
                isLoading ?
                    <div>
                        <Backdrop
                            open={isLoading}
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        >
                            <CircularProgress color="inherit" />
                            <Typography sx={{ pl: 2 }}>
                                <Text tid={"oppdaterermelding"} />
                            </Typography>
                        </Backdrop >
                    </div>
                    :
                    <div>
                        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                            <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openSnackError} autoHideDuration={60000} onClose={handleCloseSnackError} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
                            <Alert onClose={handleCloseSnackError} severity="error" sx={{ width: '100%' }}>
                                {snackMelding}
                            </Alert>
                        </Snackbar>

                        <ToppMeny />
                        <Breadcrumbs aria-label="breadcrumb" sx={{ padding: 1 }}>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/forsiden"
                            >
                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                <Text tid="forsiden" />
                            </Link>
                            <Link
                                underline="hover"
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="inherit"
                                href="/taksameter"
                            >
                                Innmelding
                            </Link>
                            <Typography
                                sx={{ display: 'flex', alignItems: 'center' }}
                                color="text.primary"
                            >
                                Aktornavn
                            </Typography>
                        </Breadcrumbs>

                        <Grid container spacing={2} p={2}>
                            <Grid size={{ xs: 6 }}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Box display="flex" alignItems="center" sx={{ marginBottom: '8px' }}>
                                            <BusinessIcon sx={{ marginRight: '4px', color: "text.secondary" }} />
                                            <Typography variant="body1" color="text.secondary">
                                                Juridisk eier av instrument(ene)
                                            </Typography>
                                        </Box>
                                        <Divider sx={{ marginBottom: '10px' }} />
                                        <Typography variant="h6" align="center" color="text.secondary">
                                            {aktorEier?.Navn}
                                        </Typography>
                                        <Typography align="center" color="text.secondary">
                                            {aktorEier?.OrgNummer}
                                        </Typography>
                                        <Grid container>
                                            <Grid size={{ xs: 4 }}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <HomeIcon className='jvtexticon' /> {aktorEier?.Besoksadresse}<br />
                                                    {aktorEier?.Besokspostnummer} {aktorEier?.Besokssted}
                                                </Typography>
                                            </Grid>
                                            <Grid size={{ xs: 4 }}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <PhoneIcon className='jvtexticon' /> {aktorEier?.Mobilnummer}
                                                </Typography>
                                            </Grid>
                                            <Grid size={{ xs: 4 }}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <EmailIcon className='jvtexticon' /> {aktorEier?.Epost}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid size={{ xs: 6 }}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Box display="flex" alignItems="center" sx={{ marginBottom: '8px' }}>
                                            <PlaceIcon sx={{ marginRight: '4px', color: "text.secondary" }} />
                                            <Typography variant="body1" color="text.secondary">
                                                Lokasjon til instrument(ene) (underenhet)
                                            </Typography>
                                        </Box>
                                        <Divider sx={{ marginBottom: '10px' }} />
                                        <Typography variant="h6" align="center" color="text.secondary">
                                            {aktor?.Navn}
                                        </Typography>
                                        <Typography align="center" color="text.secondary">
                                            {aktor?.OrgNummer}
                                        </Typography>
                                        <Grid container>
                                            <Grid size={{ xs: 4 }}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <HomeIcon className='jvtexticon' /> {aktor?.Besoksadresse}<br />
                                                    {aktor?.Besokspostnummer} {aktor?.Besokssted}
                                                </Typography>
                                            </Grid>
                                            <Grid size={{ xs: 4 }}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <PhoneIcon className='jvtexticon' /> {aktor?.Mobilnummer}
                                                </Typography>
                                            </Grid>
                                            <Grid size={{ xs: 4 }}>
                                                <Typography variant="body2" color="text.secondary" className='jvtypeicon' gutterBottom>
                                                    <EmailIcon className='jvtexticon' /> {aktor?.Epost}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Box display="flex" justifyContent="flex-end">
                                            <Button variant="contained" color="primary" /* onClick={ModalEndreAktorAapne} */ size="small"><EditIcon className='jvbtnicon' /> {Text({ tid: "endre" })}</Button>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid size={{ xs: 12 }}>
                                <Tabs value={valueTab} onChange={handleChangeTab} aria-label="Tabs" sx={{ mb: 2 }}>
                                    <Tab icon={<CheckIcon color="success" />} label={"Aktive instrumenter (inst)"} iconPosition="start" />
                                    <Tab icon={<DeleteIcon color="error" />} label={"Kasserte instrumenter (kas)"} iconPosition="start" />
                                    <Tab icon={<AddIcon color="info" />} label={"Legg til nytt instrument"} iconPosition="start" />
                                </Tabs>

                                {/* Aktive instrumenter tab */}
                                <TabPanel value={valueTab} index={0}>
                                    <div>
                                        {Object.keys(instrumenterGruppert).map((key) => {
                                            const instrumentType = instrumenterGruppert[key];
                                            return (
                                                <div key={key}>
                                                    <h2>{instrumentType.InstrumentType}</h2>
                                                    {instrumentType.Instruments.map((instrument) => (
                                                        <>
                                                            <Accordion>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1-content"
                                                                    id="panel1-header"
                                                                >
                                                                    [OK] {instrument.Merking} - [{instrument.Fabrikat} / {instrument.Modell}] - Serienummer: {instrument.Serienummer}
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Card key={instrument.Serienummer} sx={{ mb: 2 }}>
                                                                        <CardContent>
                                                                            <Grid container spacing={2}>
                                                                                <Grid size={{ xs: 12 }}>
                                                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                                                        <Box display="flex" alignItems="center">
                                                                                        </Box>
                                                                                        <Box display="flex" gap={1} justifyContent="flex-end">
                                                                                            <Button
                                                                                                variant="contained"
                                                                                                color="primary"
                                                                                                size="small"
                                                                                                sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto" }}
                                                                                            >
                                                                                                <EditIcon className='jvbtnicon' /> Endre
                                                                                            </Button>
                                                                                            <Button
                                                                                                variant="contained"
                                                                                                color="error"
                                                                                                size="small"
                                                                                                sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto", marginLeft: 1 }}
                                                                                            >
                                                                                                <DeleteForeverIcon className='jvbtnicon' /> Kasser instrument
                                                                                            </Button>
                                                                                        </Box>
                                                                                    </Box>

                                                                                    <Box display="inline-flex" alignItems="center" sx={{ color: '#2a3eb1' }}>
                                                                                        <CalendarMonthIcon sx={{ fontSize: 16 }} />
                                                                                        <Typography variant="body1" color="text.secondary" sx={{ mr: 1 }}>
                                                                                            {instrument.OpprettelsesDato ? moment(instrument.OpprettelsesDato).format('DD.MM.YYYY') : (
                                                                                                <Typography variant="body2" color="warning">
                                                                                                    (Opprettelsesdato mangler)
                                                                                                </Typography>
                                                                                            )}
                                                                                        </Typography>
                                                                                        <FingerprintIcon sx={{ fontSize: 16 }} />
                                                                                        <Typography variant="body1" color="text.secondary" sx={{ mr: 1 }}>
                                                                                            {instrument.Serienummer ? instrument.Serienummer : (
                                                                                                <Typography variant="body2" color="warning">
                                                                                                    (Serienummer mangler)
                                                                                                </Typography>
                                                                                            )}
                                                                                        </Typography>
                                                                                        <QrCodeIcon sx={{ fontSize: 16 }} />
                                                                                        <Typography variant="body1" color="text.secondary">
                                                                                            {instrument.Jvstrekkode ? instrument.Jvstrekkode : (
                                                                                                <Typography variant="body2" color="warning">
                                                                                                    (JV strekkode mangler)
                                                                                                </Typography>
                                                                                            )}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                </Grid>
                                                                                <Grid size={{ xs: 12 }}>
                                                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                                                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                                                                                            Instrumentdeler
                                                                                        </Typography>
                                                                                        <Box display="flex" gap={1} justifyContent="flex-end">
                                                                                            <Button
                                                                                                variant="contained"
                                                                                                color="primary"
                                                                                                size="small"
                                                                                                sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto" }}
                                                                                            >
                                                                                                +
                                                                                            </Button>
                                                                                        </Box>
                                                                                    </Box>

                                                                                    <TableContainer component={Paper}>
                                                                                        <Table>
                                                                                            <TableHead sx={{ backgroundColor: '#2196f3' }}>
                                                                                                <TableRow>
                                                                                                    <TableCell sx={{ color: 'white' }}>Status</TableCell>
                                                                                                    <TableCell sx={{ color: 'white' }}>Merking</TableCell>
                                                                                                    <TableCell sx={{ color: 'white' }}>Serienummer</TableCell>
                                                                                                    <TableCell sx={{ color: 'white' }}>Instrumenttype</TableCell>
                                                                                                    <TableCell sx={{ color: 'white' }}>Instrumentdeltype</TableCell>
                                                                                                    <TableCell sx={{ color: 'white' }}>Kategori/Klasse</TableCell>
                                                                                                    <TableCell sx={{ color: 'white' }}>Kapasitet</TableCell>
                                                                                                    <TableCell sx={{ color: 'white' }}>Delinger</TableCell>
                                                                                                    <TableCell></TableCell>
                                                                                                </TableRow>
                                                                                            </TableHead>
                                                                                            <TableBody>
                                                                                                <TableRow key={instrument.Serienummer}>
                                                                                                    <TableCell>{instrument.Status}</TableCell>
                                                                                                    <TableCell>{instrument.Merking}</TableCell>
                                                                                                    <TableCell>{instrument.Serienummer}</TableCell>
                                                                                                    <TableCell>{instrument.InstrumentType}</TableCell>
                                                                                                    <TableCell>{instrument.Instrumentdeltype}</TableCell>
                                                                                                    <TableCell>{instrument.Kategori}</TableCell>
                                                                                                    <TableCell>{instrument.MaksKapasitet}</TableCell>
                                                                                                    <TableCell>{instrument.Delinger}</TableCell>
                                                                                                    <TableCell align="right">
                                                                                                        <Button variant="contained" color="primary" size="small" sx={{ mr: 1, minWidth: '32px' }}>
                                                                                                            <EditIcon />
                                                                                                        </Button>
                                                                                                        <Button variant="contained" color="error" size="small" sx={{ minWidth: '32px' }}>
                                                                                                            <DeleteForeverIcon />
                                                                                                        </Button>
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            </TableBody>
                                                                                        </Table>
                                                                                    </TableContainer>
                                                                                </Grid>

                                                                                <Grid size={{ xs: 12 }}>
                                                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                                                        <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                                                                                            Plomber
                                                                                        </Typography>
                                                                                        <Box display="flex" gap={1} justifyContent="flex-end">
                                                                                            <Button
                                                                                                variant="contained"
                                                                                                color="primary"
                                                                                                size="small"
                                                                                                sx={{ whiteSpace: "nowrap", display: "inline-flex", alignItems: "center", minWidth: "auto" }}
                                                                                            >
                                                                                                +
                                                                                            </Button>
                                                                                        </Box>
                                                                                    </Box>

                                                                                    <TableContainer component={Paper}>
                                                                                        <Table>
                                                                                            <TableHead sx={{ backgroundColor: '#2196f3' }}>
                                                                                                <TableRow>
                                                                                                    <TableCell sx={{ color: 'white' }}>Navn</TableCell>
                                                                                                    <TableCell sx={{ color: 'white' }}>Type</TableCell>
                                                                                                    <TableCell sx={{ color: 'white' }}>Nummer</TableCell>
                                                                                                    <TableCell sx={{ color: 'white' }}>Plassering</TableCell>
                                                                                                </TableRow>
                                                                                            </TableHead>
                                                                                            <TableBody>
                                                                                                {instrument.Plombenavn || instrument.Plombetype || instrument.Plombenummer || instrument.Plombeplassering ? (
                                                                                                    <TableRow key={instrument.Serienummer}>
                                                                                                        <TableCell>{instrument.Plombenavn}</TableCell>
                                                                                                        <TableCell>{instrument.Plombetype}</TableCell>
                                                                                                        <TableCell>{instrument.Plombenummer}</TableCell>
                                                                                                        <TableCell>{instrument.Plombeplassering}</TableCell>
                                                                                                    </TableRow>
                                                                                                ) : (
                                                                                                    <TableRow>
                                                                                                        <TableCell colSpan={4} align="left">
                                                                                                            <Box sx={{
                                                                                                                backgroundColor: '#FFF8E1',
                                                                                                                border: '1px solid #FFECB3',
                                                                                                                borderRadius: '4px',
                                                                                                                padding: '8px',
                                                                                                                flexDirection: 'column',
                                                                                                            }}
                                                                                                            >
                                                                                                                <Box display="inline-flex" alignItems="center" sx={{ color: 'text.secondary' }}>
                                                                                                                    <InfoIcon />
                                                                                                                    <Typography variant="body1" color="text.secondary" sx={{ ml: 1 }}>
                                                                                                                        Vi har ingen registrerte plomber på dette instrumentet.
                                                                                                                    </Typography>
                                                                                                                </Box>
                                                                                                                <Box>
                                                                                                                    <Button variant="contained" color="warning" size='small' sx={{ mt: 1 }}>
                                                                                                                        Meld plombebrudd
                                                                                                                    </Button>
                                                                                                                </Box>
                                                                                                            </Box>
                                                                                                        </TableCell>
                                                                                                    </TableRow>
                                                                                                )}
                                                                                            </TableBody>

                                                                                        </Table>
                                                                                    </TableContainer>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </CardContent>
                                                                    </Card>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </>
                                                    ))}
                                                </div>
                                            );
                                        })}
                                    </div>

                                </TabPanel>

                                {/* Kasserte instrumenter tab */}
                                <TabPanel value={valueTab} index={1}>
                                    ...
                                </TabPanel>

                                {/* Legg til nytt instrument tab */}
                                <TabPanel value={valueTab} index={2}>
                                    ...
                                </TabPanel>
                            </Grid>
                        </Grid>
                    </div>
            }
        </main >
    )
}

export default InnsendingDetaljer;